.pages-timeline-button {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px #0000001a;
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  align-items: center;
  width: auto;
  overflow: hidden;
  z-index: 1;
  transition: background 0.25s;

  &:hover {
    background: #ffffff6b;
  }

  > svg {
    transform: rotateZ(-90deg);
    transition: transform 0.25s;
  }

  &.opened {
    > svg {
      transform: rotateZ(0deg);
      transition: transform 0.25s;
    }
  }

  span {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: right;
  }
}

.pages-timeline-container {
  width: 100%;
  padding: 10px 16px;
  height: auto;
  z-index: 1;
  display: block;
  border-top: 1px solid #d1d5db;
  z-index: 10;

  .pages-timeline-content {
    width: 100%;
    display: flex;
    gap: 30px;
    position: relative;
    height: 100%;
  }

  .timeline-left {
    width: auto;
    max-width: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    background: #e8e8e8;
    float: left;
    padding: 30px 10px 0;
    .play-button {
      padding: 13px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 8px 0px #0000001a;
      transition: background 0.25s;

      &:hover {
        background: #ffffff6b;
      }
    }
    .timeline-time {
      font-family: Poppins;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0%;
      margin-top: 10px;
    }
  }

  .timeline-right-container {
    position: absolute;
    margin-left: 100px;
    padding-right: 100px;
    padding-bottom: 10px;
    overflow-x: auto;
    width: calc(100% - 100px);
  }

  .timeline-right-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .timeline-right-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #d1d5db;
    transition: 0.25s;

    &:hover {
      background-color: #b5bbc3;
    }
  }

  .timeline-right-container::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e5e7eb;
    margin: 8px;
  }

  .timeline-right,
  .page-preview-container {
    width: 100%;
    height: 100%;
  }

  .timeline-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .timeline-right-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    overflow-x: visible;
    max-height: 100px;

    .audio-bar {
      width: 100%;
      height: 30px;
      background: #27272725;
      border-radius: 10px;
    }

    .audio-container {
      width: 100%;
      height: 30px;
      background: #13a055;
      border-radius: 10px;
      flex-shrink: 0;
      transition: 0.2s;

      &:hover {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1019607843);
        transform: scaleY(1.0001);
      }

      .audio-menu {
        position: absolute;
        display: none;
        top: 5px;
        height: 20px;
        padding: 8px 6px;
        background: #11111140;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        svg {
          color: #d9d9d9;
        }
      }
    }
  }

  .polotno-pages-container {
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    position: relative;

    .timeline-cursor {
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: black;
      top: 0;
      left: 300px;
    }

    .page-preview-container {
      height: 80px;
      position: relative;
      width: 130px;
      border: 1.5px solid transparent;
      &:hover {
        border-color: #a3a0a098;
        .timeline-card-anchor {
          opacity: 1;
        }
      }
      &.active {
        border: 1.5px solid var(--kg-primary);
      }
      &.add-page-button {
        background: #d1d5db;
        cursor: pointer;
      }
      > div {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background-repeat: repeat-x;
        background-size: auto 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .timeline-card-time {
        position: absolute;
        bottom: 3px;
        left: 8px;
        border-radius: 10px;
        padding: 0 5px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0%;
        text-align: right;
        color: #ffffff;
        background: #11111140;
      }
      .timeline-card-anchor {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 0px;
        width: 6px;
        height: 40%;
        transform: translateY(-50%) translateX(-3px);
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.6);
        cursor: ew-resize;
        opacity: 0.2;
        transition: opacity 0.25s;
      }
    }
  }

  .timeline-card-container {
    display: flex;
    position: relative;
    border-radius: 15px;
    &:hover {
      .polotno-page-menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .page-preview-container {
    display: flex;
    position: relative;
    border-radius: 15px;

    .timeline-options-menu {
      position: absolute;
      top: 5px;
      right: 10px;
      background: transparent;
      transition: 0.25s;
      svg {
        color: transparent;
      }
    }
    &:hover {
      .polotno-page-menu {
        opacity: 1;
        pointer-events: auto;
      }

      .timeline-options-menu {
        background: #11111140;
        svg {
          color: #d9d9d9;
        }
        &:hover {
          svg {
            color: #fff;
          }
          background: #11111186;
        }
      }
    }
    .timeline-options-menu[aria-expanded='true'] {
      background: #a7a6a640;
      border: 1px solid #11111140;
    }
  }
}

.polotno-editor-bottom-buttons {
  position: relative;
  height: 0px;
  width: 100%;

  .timeline-button {
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: auto;
  }

  .zoom-buttons {
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }
}

.zoom-button-container {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  height: 36px;
  box-shadow: 0px 4px 8px 0px #0000001a;

  button {
    &.button-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &.button-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    height: 100%;
    background: #4b5563;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: right;
    padding: 8px 10px;
  }
}
